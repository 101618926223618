body {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to bottom left, #3b55e5, #60e1e0);
}

#root {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left, #3b55e5, #60e1e0);
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.orderSummary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 20px;
}
.shipping {
  /* border: 1px solid ¯red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
}
.productList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 17px;
}
.files-dropzone {
  margin-top: 20px;
  cursor: pointer;
  width: 20vw;
  border-radius: 10px;
  background-color: #eaf2ff;
  padding: 10px;
}
